import React , { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftNavigation from "./left-navigation";
import { SearchIcon } from "./icons.component";
import UserProfile from "./userProfile";
import PopupAddUser from "./Pop-ups/admin-add-user";
import { Alert, CustomDialog, Confirm } from "react-st-modal";
import Select from "react-select";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
import SupervisorRejectFile from "./Pop-ups/supervisor-reject-file"
import Excel from "../assets/images/excel.png"
import Logo from '../assets/images/Logo.svg';
import {BiMenuAltRight} from "react-icons/bi"

const schema = yup.object().shape({
  state: yup
    .string()
    .required("State is required!"),
  district: yup
    .string()
    .required("district is required!"),
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
});

const SupervisorUserList = () => {

  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);

  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  const [roleBaseDetails, setRoleBaseDetails] = useState({});
  const [imgSrc, setImgSrc] = useState([]);
  // const [allDistrict, setAllDistrict] = useState([]);
  // const [resultData, setResultData] = useState([]);

  useEffect(() => {
    setStates([{ label: userLogin.user.state, value: userLogin.user.state }])
  }, []);
  

  useEffect(() => {
    
    async function getcoordinators() {
      const url = `${process.env.REACT_APP_URL}/getsupervisorcoordinators`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = {
        state:state,
        district:district,
        supervisorID: userLogin.user.id
       };
      console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("Coordinator", res.data.data);
          setCoordinators(
            res.data.data.map(( coordinator ) => ({ label: coordinator.name, value: coordinator.name }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getcoordinators();

    // async function getrolebasedetails() {
    //   const url = `${process.env.REACT_APP_URL}/getrolebasedetails`;
    //   const headers = reqHeader(true, true, true);
    //   console.log("header", headers)
    //   const params = {
    //     state, district
    //   }
    //   //console.log("params", params)
    //   const config = { params, headers };
    //   await axios
    //     .get(url, config)
    //     .then((res) => {
    //       //console.log("supervisor role", res.data.data)
    //       setRoleBaseDetails({"Supervisor":res.data.data.supervisor.length == 0 ? "" : res.data.data.supervisor[0].name, "TeamLead":res.data.data.teamlead.length == 0 ? "" : res.data.data.teamlead[0].name, "Manager":  res.data.data.manager.length == 0 ? "" : res.data.data.manager[0].name })
    //     })
    //       .catch((err) => {});
    // }
    // getrolebasedetails();
  }, [district]);

  const onChangeState = (e) => {
    setValue('state', e.target.value,  { shouldValidate: true })
    setState(e.target.value);
    setDistricts([{ label: userLogin.user.district, value: userLogin.user.district }])
  }

  
  const OnChangeDistrict = (e) => {
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);
  }
  
  const onChangeCoordinator = (e) => {
    setValue('coordinator', e.target.value,  { shouldValidate: true })
    setCoordinator(e.target.value);
  
    // async function getallFiles() {
    //   const url = `${process.env.REACT_APP_URL}/getallfiles`;
    //   const headers = reqHeader(true, true, true);
    //   console.log("header", headers)
    //   const params = {
    //     state, district, coordinator:e.target.value
    //   }
    //   console.log("params", params)
    //   const config = { params, headers };
    //   await axios
    //     .get(url, config)
    //     .then((res) => {
    //       const boolsorted = res.data.data.sort((a,b) => a.isQcAccepted - b.isQcAccepted);
    //       let sorted=boolsorted.sort((a, b) => a.status.localeCompare(b.status));
    //       setFileList(sorted)
    //       const times = [];
    //       res.data.data.map((filedata)=>{
    //         if(filedata.status == "Accepted"){
    //           times.push(filedata.fileDuration)
    //         }         
    //         })
    //         const sum = sumTime(times)
    //         setTotalDuration(sum)
    //     })
    //       .catch((err) => {});
    // }
    // getallFiles();

    async function getUsers() {
      const url = `${process.env.REACT_APP_URL}/getcoordinatorwiseusers`;
      const headers = reqHeader(true, true, true);
      const params = {state, district,coordinator:e.target.value}
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          setUsersList(res.data.data);
          //console.log("res.data.data", res.data.data)
          const times = [];
          res.data.data.map((filedata)=>{
              times.push(filedata.speechDuration)         
            })
            const sum = sumTime(times)
            setTotalDuration(sum)

       })
        .catch((err) => {});
    }
    getUsers();
  }

  //To calculate total speech duration of Coordinator
const sumTime = (times) => {
    //console.log("Inside sumtime", times)
    let sumSeconds = 0;
      
    times.forEach(time => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });
    // return new Date(sumSeconds * 1000).toISOString().substr(11, 8);

    var sec_num = parseInt(sumSeconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    //console.log("sum total", hours + ':' + minutes + ':' + seconds);
    return hours + ':' + minutes + ':' + seconds;
  }

const rowClicked = (mobile, name, age, gender, speakerID) => {
  // console.log("mobile, name, age, gender", mobile, name, age, gender);
  history.push({ pathname : "/supervisor-userfilelist",
  state: {mobile:mobile, name:name, age:age, gender:gender, speakerID:speakerID}
})
};

let columns = useMemo(
  () => [
    {
      id: "_id",
      Header: "Id",
      accessor: "_id",
      sortType: "basic",
      filter: "text",
    },
    {
      Header: "Mobile",
      accessor: "mobile",
      sortType: "basic",
      filter: "text",
      Cell: ({ row }) => {
        //console.log("row", row);
        return  <span style={{textDecoration: "underline #22A4EE",cursor: "pointer", color:"#22A4EE"}}
        onClick={() => rowClicked(row.original.mobile, row.original.name, row.original.age, row.original.gender, row.original.speakerID)}>
        {row.original.mobile}
      </span>
      },
    },
    {
      Header: "latitude",
      accessor: "latitude",
      sortType: "basic",
      filter: "text",
      Cell: ({ row }) => {
        return(<div>{Number(row.original.latitude).toFixed(2)}</div>
        )
      },
    },
    {
      Header: "longitude",
      accessor: "longitude",
      sortType: "basic",
      filter: "text",
      Cell: ({ row }) => {
        return(<div>{Number(row.original.longitude).toFixed(2)}</div>
        )
      },
    },
    {
      Header: "Name",
      accessor: "name",
      sortType: "basic",
      filter: "text",
      //sticky: "left",
    },
    {
      Header: "Total Speech Duration",
      accessor: "speechDuration",
      sortType: "basic",
      filter: "text",
    },
  ],
  []
);


  const menuToggler = () =>{
    const leftSideBar = document.querySelector(".leftSideBar")
    leftSideBar.style.display = "block"
  }

    return (
      <>
        <div className="container entitiCustomerWrap d-flex mobileView">
        <img src={Logo} alt="Logo" className="logoImg"/>
          <LeftNavigation />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Supervisor
              </div>
              <BiMenuAltRight className="toggleMenu"
              onClick={() => menuToggler()}/>
              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between regionSelectWrap">
                <div className="regionselect d-flex">
                    <div className = {`inputWrap ${
                         errors.state ? "error" : ""
                      }`}>
                      <select
                          {...register("state")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeState(e)}
                        >
                          {}
                           <option value="">Select State</option>
                          {states && states.map((state)=>{
                            return <option key={state.value} value={state.value}>{state.label}</option>
                          })}
                        </select>
                        {errors.state && (
                        <span className="inputErrorMsg">
                          {errors.state?.message}
                        </span>
                      )}
                    </div>
                     <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                      <select className="error"
                          {...register("district")}
                          // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                            onChange={(e) => OnChangeDistrict(e)}
                        >
                           <option value="">Select District</option>
                          {districts && districts.map((district)=>{
                            console.log("district", district)
                            return <option key={district.value} value={district.value}>{district.label}</option>
                          })}
                        </select>
                        {errors.district && (
                        <span className="inputErrorMsg">
                          {errors.district?.message}
                        </span>
                      )}
                    </div>
                    <div className = {`inputWrap ${
                         errors.coordinator ? "error" : ""
                      }`}>
                      <select
                          {...register("coordinator")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeCoordinator(e)}
                        >
                          {}
                           <option value=""> Co-ordinator</option>
                          {coordinators && coordinators.map((coordinator)=>{
                            console.log("Option coordinator", coordinator.value)
                            return <option key={coordinator.value} value={coordinator.value}>{coordinator.label}</option>
                          })}
                        </select>
                        {errors.coordinator && (
                        <span className="inputErrorMsg">
                          {errors.coordinator?.message}
                        </span>
                      )}
                    </div>
                </div>
              </div>

              <div className="d-flex justify-between totalBox">
                <div className="adminDetails">
                  <div>
                    <label>Team Lead Name:</label>
                    <span className="value">{userLogin.user.teamleadName}</span>
                  </div>
                  <div>
                    <label>Co-Ordinator Name:</label>
                    <span className="value">{coordinator}</span>
                  </div>
                  
                  <div>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={userList}
                      count={userList.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Files"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default SupervisorUserList;