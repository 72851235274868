import React , { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import { format } from "date-fns";
// import { CSVLink } from "react-csv";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
import Excel from "../assets/images/excel.png"
import Logo from '../assets/images/Logo.svg';
import {BiMenuAltRight} from "react-icons/bi"

const schema = yup.object().shape({
  state: yup
    .string()
    .required("State is required!"),
  district: yup
    .string()
    .required("district is required!"),
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
});

const SupervisorFileList = () => {

  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);

  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  const [imgSrc, setImgSrc] = useState([]);


  useEffect(() => {
    setStates([{ label: userLogin.user.state, value: userLogin.user.state }])
  }, []);
  

  useEffect(() => {
    
    async function getcoordinators() {
      const url = `${process.env.REACT_APP_URL}/getsupervisorcoordinators`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = {
        state:state,
        district:district,
        supervisorID: userLogin.user.id
       };
      console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("Coordinator", res.data.data);
          setCoordinators(
            res.data.data.map(( coordinator ) => ({ label: coordinator.name, value: coordinator.name }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getcoordinators();
  }, [district]);

  const onChangeState = (e) => {
    setValue('state', e.target.value,  { shouldValidate: true })
    setState(e.target.value);
    setDistricts([{ label: userLogin.user.district, value: userLogin.user.district }])
  }
  
  const OnChangeDistrict = (e) => {
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);
  }
  
  const onChangeCoordinator = (e) => {
    setValue('coordinator', e.target.value,  { shouldValidate: true })
    setCoordinator(e.target.value);
  
    async function getallFiles() {
      const url = `${process.env.REACT_APP_URL}/getallfiles`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = {
        state, district, coordinator:e.target.value
      }
      //console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          const boolsorted = res.data.data.sort((a,b) => a.isQcAccepted - b.isQcAccepted);
          let sorted=boolsorted.sort((a, b) => a.status.localeCompare(b.status));
          setFileList(sorted)
          const times = [];
          res.data.data.map((filedata)=>{
            if(filedata.status == "Accepted"){
              times.push(filedata.speechDuration)
            }         
            })
            const sum = sumTime(times)
            setTotalDuration(sum)
        })
          .catch((err) => {});
    }
    getallFiles();

  }

  const sumTime = (times) => {
    let sumSeconds = 0;
      
    times.forEach(time => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });
    
    var sec_num = parseInt(sumSeconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    //console.log("sum total", hours + ':' + minutes + ':' + seconds);
    return hours + ':' + minutes + ':' + seconds;
  }

//   const imageclick = (imgpath) => {
//     console.log("imgpath", imgpath)
//     setImgSrc(imgpath)
//   }

//   const onQCRejectFile = async(fid, duration, userId, e) => {

//     const headers = reqHeader(true, true, true);
//       let reason = getValues("reasonforrejection");
//     if(reason){
//       let body={
//         id:fid,
//         reason:reason,
//         role: "Supervisor",
//         fileDuration: duration,
//         userID: userId,
//       }
//       console.log("body", body)
//       const config = { headers };
//       let url = `${process.env.REACT_APP_URL}/rejectfile`;

//       await axios
//         .put(url,body,config)
//         .then((res) => {
//           let rejectbtn=document.getElementById(e.target.id);
//           rejectbtn.disabled=true;
//         });
//     }else{
//       toast.error("Please select reason for rejection")
//     }
      
// }



  let columns = useMemo(
    () => [
      {
        Header: "File name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {row.original.fileName}
            </div>
          );
        },
      },
      {
        Header: "Image name",
        accessor: "imageName",
        sortType: "basic",
        filter: "text",
        // Cell: ({ value, column, row }) => {
        //   return (
        //     <div onClick={(e) =>imageclick(row.original.imageLocation)}>
        //       {row.original.imageName}
        //     </div>
        //   );
        // }
      },
      {
        Header: "Audio",
        accessor: "fileLocation",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <audio src={row.original.fileLocation} controls controlsList="nodownload"></audio>
            </div>
          );
        },
      },
      {
        Header: "Vendor Name",
        accessor: "vendorName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Recorded on",
        accessor: "recordedOn",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {format(
                    new Date(row.original.recordedOn),
                    "dd/MM/yyyy"
                  )}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
             {row.original.status == "Accepted" && row.original.isQcAccepted == false ? "QcPending" : row.original.status}
            </div>
          );
        },
      },
      // {
      //   Header: "Reason for rejection",
      //   //accessor: "recordedOn",
      //   sortType: "basic",
      //   filter: "text",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       row.original.status == "Accepted" && (!row.original.isQcAccepted)?
      //       <div>
      //           <div className = {`inputWrap ${
      //                    errors.reasonforrejection ? "error" : ""
      //                 }`}>
      //                 <select
      //                     {...register("reasonforrejection")}
      //                     onChange={(e) => setValue('reasonforrejection', e.target.value,  { shouldValidate: true })} // Using setValue
      //                   >
      //                      <option value="">Please select reason</option>
      //                      <option value="Audio > 25 seconds">Audio &gt; 25 seconds</option>
      //                      <option value="Audio < 10 seconds">Audio &lt; 10 seconds</option>
      //                      <option value="Not audible">Not audible</option>
      //                      <option value="Background Noise">Background Noise</option>
      //                      <option value="Gender mismatch">Gender mismatch</option>
      //                      <option value="Image & Audio mismatch">Image & Audio mismatch</option>
      //                      <option value="Speech is not natural">Speech is not natural</option>
      //                      <option value="Multiple speakers">Multiple speakers</option>
      //                      <option value="Abusive language">Abusive language</option>
      //                      <option value="Language Mismatch">Language Mismatch</option>
      //                   </select>
      //                   {errors.reasonforrejection && (
      //                   <span className="inputErrorMsg">
      //                     {errors.reasonforrejection?.message}
      //                   </span>
      //                 )}
      //               </div>
      //       </div> : ""
      //     );
      //   },
      // },
      // {
      //   Header: "Reject",
      //   accessor: "",
      //   sortType: "basic",
      //   filter: "text",
      //   // fixed: "left",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       row.original.status == "Accepted" && (!row.original.isQcAccepted)?
      //       <div>
      //         <button id={`rejbtn${row.original._id}`}            
      //           onClick={(e) => onQCRejectFile(row.original._id, row.original.fileDuration, row.original.userID, e)}
      //           className="btn blueBtn-fill">
      //           Reject
      //         </button>

      //       </div> : ""
      //     );
      //   }
      // },
    ],
    []
  );


  // const headers = [
  //   { label: "Image name", key: "imageName" },
  //   { label: "File name", key: "fileName" },
  //   { label: "Duration", key:"fileDuration" },
  //   { label: "Speaker name", key: "vendorName" },
  //   { label: "Recorded On", key:"recordedOn" },
  //   { label: "Coordinator", key: "coordinatorName"},
  //   { label: "State", key: "state"},
  //   { label: "District", key: "district"},
  //   { label: "Pincode", key: "pincode"},
  //   { label: "Age", key: "age" },
  //   { label: "Gender", key: "gender"},
  //   { label: "Language", key: "language" },
  //   { label: "Education", key: "qualification" },
  //   { label: "Staying Years", key: "stayingyears" },
  //   { label: "Socio-economic Status", key: "socioeconomicstatus" },
  //   { label: "Speaker ID", key: "speakerID"},
  //   { label: "Status", key: "status" },
  // ];

  const menuToggler = () =>{
    const leftSideBar = document.querySelector(".leftSideBar")
    leftSideBar.style.display = "block"
  }

    return (
      <>
        <div className="container entitiCustomerWrap d-flex mobileView">
        <img src={Logo} alt="Logo" className="logoImg"/>
          <ToastContainer />
          <LeftNavigation />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Supervisor
              </div>
              <BiMenuAltRight className="toggleMenu"
              onClick={() => menuToggler()}/>
              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between regionSelectWrap">
                <div className="regionselect d-flex">
                    <div className = {`inputWrap ${
                         errors.state ? "error" : ""
                      }`}>
                      <select
                          {...register("state")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeState(e)}
                        >
                          {}
                           <option value="">Select State</option>
                          {states && states.map((state)=>{
                            return <option key={state.value} value={state.value}>{state.label}</option>
                          })}
                        </select>
                        {errors.state && (
                        <span className="inputErrorMsg">
                          {errors.state?.message}
                        </span>
                      )}
                    </div>
                     <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                      <select className="error"
                          {...register("district")}
                          // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                            onChange={(e) => OnChangeDistrict(e)}
                        >
                           <option value="">Select District</option>
                          {districts && districts.map((district)=>{
                            console.log("district", district)
                            return <option key={district.value} value={district.value}>{district.label}</option>
                          })}
                        </select>
                        {errors.district && (
                        <span className="inputErrorMsg">
                          {errors.district?.message}
                        </span>
                      )}
                    </div>
                    <div className = {`inputWrap ${
                         errors.coordinator ? "error" : ""
                      }`}>
                      <select
                          {...register("coordinator")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeCoordinator(e)}
                        >
                          {}
                           <option value=""> Co-ordinator</option>
                          {coordinators && coordinators.map((coordinator)=>{
                            console.log("Option coordinator", coordinator.value)
                            return <option key={coordinator.value} value={coordinator.value}>{coordinator.label}</option>
                          })}
                        </select>
                        {errors.coordinator && (
                        <span className="inputErrorMsg">
                          {errors.coordinator?.message}
                        </span>
                      )}
                    </div>
                </div>
              </div>

              <div className="d-flex justify-between totalBox">
                <div className="adminDetails">
                  <div>
                    <label>Team Lead Name:</label>
                    <span className="value">{userLogin.user.teamleadName}</span>
                  </div>
                  <div>
                    <label>Co-Ordinator Name:</label>
                    <span className="value">{coordinator}</span>
                  </div>
                  
                  <div>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div>
                </div>
                {/* <CSVLink data={fileList} filename={state + "_" + district + "_" + coordinator} headers={headers} className="btn btnExcelGreen" target="_blank">
                <img src={Excel} alt="Excel"/>
                  Export FileList
                </CSVLink> */}
                {/* <div className="hover_img" style={{textAlign:"end"}}>
                  <a href="#"><span><img src={imgSrc} alt="" style={{width:"200px", height:"200px"}}/></span></a>
                </div> */}
              </div>
            </div>
            
            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={fileList}
                      count={fileList.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Files"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default SupervisorFileList;