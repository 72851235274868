import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Link, Redirect, useHistory} from "react-router-dom";
import reqHeader from "../helper/reqHeader"
import LeftNavigation from "./left-navigation";
import { SearchIcon } from "./icons.component";
import UserProfile from "./userProfile";
import Table from "./Table/Table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Omni from "../assets/images/omni.png"
import Logo from '../assets/images/Logo.svg';
import {BiMenuAltRight} from "react-icons/bi"



const CoordinatorUserList = () => {

  const [users, setUsersList] = useState([])
  const [fileList, setFileList] = useState([]);

  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);



  useEffect(()=>{
    async function getUsers() {
      const url = `${process.env.REACT_APP_URL}/getusers`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("res.data.data", res.data.data)
          setUsersList(res.data.data)

       })
        .catch((err) => {});
    }
    getUsers();

  },[])

  const rowClicked = (mobile, name, age, gender, speakerID, speechDuration) => {
     //console.log("mobile, name, age, gender", mobile, name, age, gender);
    history.push({ pathname : "/coordinator-userfilelist",
    state: {mobile:mobile, name:name, age:age, gender:gender, speakerID:speakerID, speechDuration:speechDuration}
  })
  };


  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          console.log("row", row)
          return  <span style={{textDecoration: "underline #22A4EE",cursor: "pointer", color:"#22A4EE"}}
          onClick={() => rowClicked(row.original.mobile, row.original.name, row.original.age, row.original.gender, row.original.speakerID, row.original.speechDuration)}>
          {row.original.mobile}
        </span>
        },
      },
      // {
      //   Header: "latitude",
      //   accessor: "latitude",
      //   sortType: "basic",
      //   filter: "text",
      //   Cell: ({ row }) => {
      //     return(<div>{Number(row.original.latitude).toFixed(2)}</div>
      //     )
      //   },
      // },
      // {
      //   Header: "longitude",
      //   accessor: "longitude",
      //   sortType: "basic",
      //   filter: "text",
      //   Cell: ({ row }) => {
      //     return(<div>{Number(row.original.longitude).toFixed(2)}</div>
      //     )
      //   },
      // },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        //sticky: "left",
      },
      {
        Header: "Total Speech Duration",
        accessor: "speechDuration",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "File Count",
        accessor: "fileCount",
        sortType: "basic",
        filter: "text",
      },
      // {
      //   Header: "Password",
      //   //accessor: "accesscode",
      //   sortType: "basic",
      //   filter: "text",
      //   fixed: "left",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <button className="btn darkGreenBtn" onClick={(e) => resendOTP(row.original)}>Resend Password</button>
      //     );
      //   },
      // },
      {
        Header: "Access Code",
        accessor: "accesscode",
        sortType: "basic",
        filter: "text",
        fixed: "left",
      },
      {
        Header: "Sign Off",
        accessor: "",
        sortType: "basic",
        filter: "text",
        //fixed: "left",
        Cell: ({ value, column, row }) => {
          //console.log("row", row)
          return (
            <button className="btn darkGreenBtn" disabled={row.original.recordingCompleted} onClick={(e) => SignOffSpeaker(row.original)}>Sign Off</button>
          );
        },
      },
    ],
  );

    const SignOffSpeaker = async(row) => {
      //console.log("row signOff", row.fileCount)
      //let recordedMinutes = row.recordedHours.split(":"); //if(recordedMinutes[1] < 5)
      // console.log("recordedMinutes", recordedMinutes)
      if(row.fileCount < 10){
        toast.error("Minimum 10 files required for Signing Off Speaker!")
      }
      else{       
        const url = `${process.env.REACT_APP_URL}/updatespeakersignoff`;
        const headers = reqHeader(true, true, true);
        //console.log("header", headers)
        const body = {
          mobile: row.mobile, speakerID: row.speakerID, userID: row._id
        }
        //console.log("body", body)
        const config = { headers };
        await axios
          .put(url, body, config)
          .then((res) => {
            if(res.data.success){
              toast.success("Speaker signedoff successfully!")
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          })
            .catch((err) => {
              toast.error(err.response.data)
            });
        }

  }

  // const resendOTP = async(row) => {

  //     const url = `${process.env.REACT_APP_URL}/resendotp`;
  //     const headers = reqHeader(true, true, true);
  //     //console.log("header", headers)
  //     const params = {
  //       mobile: row.mobile, accesscode:row.accesscode
  //     }
  //     const config = { params, headers };
  //     await axios
  //       .get(url, config)
  //       .then((res) => {
  //         if(res.data.success){
  //           toast.success("OTP sent Successfully!")
  //         }
  //       })
  //         .catch((err) => {
  //           toast.error(err.response.data)
  //         });
  // }
 

  const menuToggler = () =>{
    const leftSideBar = document.querySelector(".leftSideBar")
    leftSideBar.style.display = "block"
  }


    return (
      <>
        <div className="container entitiCustomerWrap d-flex mobileView">
        <img src={Logo} alt="Logo" className="logoImg"/>
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
              Project Co-ordinator
              </div>
              <BiMenuAltRight className="toggleMenu"
              onClick={() => menuToggler()}/>
              <UserProfile />
            </div>
            <div>

              <div className="d-flex justify-between totalBox">
                <div className="adminDetails d-flex">
                  <div>
                  <img src={Omni} alt="Omni"/>
                  <div className="d-flex flex-column">
                    <label>Supervisor Name:</label>
                    <span className="value">{userLogin.user.supervisorName}</span>
                  </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="projectCoordinatorContent">
                {/* <div className="d-flex justify-end">
                  <CSVLink data={fileList ? fileList : fileList} headers={headers} className="btn blueBtn-fill">
                    Export
                  </CSVLink>
                </div> */}

              <div className="projectContent mobileDashboard">
                {/* <div className="EntitiVendorTable"> */}
                    <Table
                      columns={columns}
                      data={users}
                      count={users.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="users"
                    />
                  {/* </div> */}
              </div>
            </div>
            
          </div>
        </div>
      </>
    );
};

export default CoordinatorUserList;